const formatSortKey = (key) => {
  return key
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join("");
};

export const createColumns = (
  data = [], // Default to an empty array if data is undefined or null
  customColumns = [], // Default to an empty array if customColumns is undefined or null
  hiddenColumns = [], // Default to an empty array if hiddenColumns is undefined or null
  renderImage = false
) => {
  if (!data || data.length === 0) {
    return [];
  }

  // Ensure data is an array and has at least one element
  const keys = data.length > 0 ? Object.keys(data[0]) : [];

  // Create default columns array
  const defaultColumns = keys
    .filter((key) => hiddenColumns && !hiddenColumns.includes(key)) // Safeguard against hiddenColumns being null/undefined
    .map((key) => {
      // Capitalize the first letter of the key and replace underscores with spaces for the column name
      const name = key
        .replace(/_/g, " ")
        .replace(/\b\w/g, (char) => char.toUpperCase());
      const sortKey = formatSortKey(key);
      return {
        name: name,
        selector: (row) => row[key],
        sortKey: sortKey,
        sortable: true,
        wrap: true,
        cell:
          (renderImage && key === "image") || key === "profile_pic"
            ? (row) => (
                <img
                  src={row.image || "/imgs/partner.png"}
                  alt={row.name || ""}
                  className="tw-rounded-full tw-border-2 tw-w-10 tw-h-10 tw-shrink-0"
                />
              )
            : undefined,
      };
    });

  // Merge default columns with custom columns
  const columns = [
    ...defaultColumns,
    ...(customColumns || []).map(({ name, selector, ui }) => ({
      name,
      selector, // Ensure custom column selector is also a string key
      sortable: false,
      wrap: true,
      // If ui is provided, include it in the column definition
      cell: ui ? (row) => ui(row) : undefined,
    })),
  ];

  return columns;
};

import React from 'react'
import { Link } from 'react-router-dom'

const Footer = () => {
    return (
        <div className="container page__container mt-auto mb-2">
            <div className="footer text-center">
                Copyright &copy; 2024 <Link to="" className='bold'>Win Life</Link>
            </div>
        </div>

    )
}

export default Footer

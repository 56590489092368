import React from "react";
import Header from "../Components/Header";
import SidebarAndContent from "../Components/SidebarAndContent";
import { Navigate, Route, Routes } from "react-router-dom";
import AddOrEditPartner from "../Pages/AddOrEditPartner/AddOrEditPartner";
import Partners from "../Pages/Partners/Dashboard";
import Dashboard from "../Pages/Partners/Dashboard";
import PartnersList from "../Pages/Partners/PartnersList";
import Tree from "../Pages/Partners/Tree";
import { USER_TYPES } from "../constants/globals";
import { useSelector } from "react-redux";
import RequestsList from "../Pages/Admin/RequestsList";
import RequestDetails from "../Pages/Admin/RequestDetails";
import UpdatePartnerData from "../Pages/AddOrEditPartner/UpdatePartnerData";
import RequestEMoney from "../Pages/Partners/RequestEMoney";
import AddNewCourse from "../Pages/Partners/AddNewCourse";
import AdminPartnersList from "../Pages/Admin/AdminPartnersList";
import Profile from "../Pages/Admin/Profile";
import UpdatePartnerProfile from "../Pages/Admin/UpdatePartnerProfile";
import PaymentDetails from "../Pages/Admin/PaymentDetails";
import Mycourses from "../Pages/Partners/courses/Mycourses";
import SingleCourse from "../Pages/Partners/courses/SingleCourse";
import CourseSingleLesson from "../Pages/Partners/courses/CourseSingleLesson";
import TransferEMoney from "../Pages/Partners/TransferEMoney";
import ReportDetails from "../Pages/Admin/ReportDetails";
import Reports from "../Pages/Admin/Reports";
import SettlementList from "../Pages/Admin/settlements/SettlementList";
import NewSettlement from "../Pages/Admin/settlements/NewSettlement";
import SettlementPartnerList from "../Pages/Admin/settlements/SettlementPartnerList";
import UnsettledPartner from "../Pages/Admin/settlements/UnsettledPartner";
import SettledPartner from "../Pages/Admin/settlements/SettledPartner";
import DirectPvHistory from "../Pages/Admin/settlements/DirectPvHistory";
import EndSettlement from "../Pages/Admin/settlements/EndSettlement";
import ReplaceEmoneyList from "../Pages/Admin/replaceEmoney/ReplaceEmoneyList";
import SettlementHistory from "../Pages/Admin/settlements/SettlementHistory";
import ReplaceEmoney from "../Pages/Partners/ReplaceEmoney";
import ReplaceEmoneyDetails from "../Pages/Admin/replaceEmoney/ReplaceEmoneyDetails";

const AuthenticatedRoutes = () => {
  const { loggedInUser } = useSelector((state) => state?.Auth);
  const currentUserType = loggedInUser?.data?.model?.user_type_id;

  return (
    <div className="layout-fluid" dir="ltr">
      <div className="mdk-header-layout js-mdk-header-layout tw-size-full">
        <Header />

        <Routes>
          <Route path="/" element={<SidebarAndContent />}>
            {currentUserType === USER_TYPES.ADMIN && (
              <>
                <Route path="/" element={<Dashboard />} />
                <Route path="/requests" element={<RequestsList />} />
                <Route
                  path="/request-details/:id"
                  element={<RequestDetails />}
                />
                <Route path="/partners-list" element={<AdminPartnersList />} />
                <Route path="/tree/:id?" element={<Tree />} />
                <Route path="/profile/:id" element={<Profile />} />
                <Route
                  path="/update-profile/:id"
                  element={<UpdatePartnerProfile />}
                />
                <Route
                  path="/payment-details/:id"
                  element={<PaymentDetails />}
                />
                <Route path="/reports" element={<Reports />} />
                <Route path="/reports/:id" element={<ReportDetails />} />
                {/* Settlements */}
                <Route path="/settlements" element={<SettlementList />} />
                <Route path="/new-settlement" element={<NewSettlement />} />
                <Route
                  path="/settlement/:id/partner-list"
                  element={<SettlementPartnerList />}
                />
                <Route
                  path="/settlement/:id/unsettled-partners/:partnerId"
                  element={<UnsettledPartner />}
                />
                <Route
                  path="/settlement/:id/settled-partners/:partnerId"
                  element={<SettledPartner />}
                />
                <Route
                  path="/settlement/direct-pv-history/:id/:partnerId"
                  element={<DirectPvHistory />}
                />
                <Route
                  path="/end-settlement/:id/"
                  element={<EndSettlement />}
                />
                <Route path="/replace-emoney" element={<ReplaceEmoneyList />} />
                <Route
                  path="/replace-emoney-details/:id"
                  element={<ReplaceEmoneyDetails />}
                />
                <Route
                  path="/settlement-history/:id/"
                  element={<SettlementHistory />}
                />
              </>
            )}

            {currentUserType === USER_TYPES.MODERATOR && (
              <>
                <Route path="/" element={<Dashboard />} />
                <Route path="/requests" element={<RequestsList />} />
                <Route
                  path="/request-details/:id"
                  element={<RequestDetails />}
                />
                <Route path="/partners-list" element={<AdminPartnersList />} />
                <Route path="/tree/:id?" element={<Tree />} />
                <Route path="/profile/:id" element={<Profile />} />
                <Route
                  path="/update-profile/:id"
                  element={<UpdatePartnerProfile />}
                />
                <Route
                  path="/payment-details/:id"
                  element={<PaymentDetails />}
                />
                <Route path="/reports" element={<Reports />} />
                <Route path="/reports/:id" element={<ReportDetails />} />
                {/* Settlements */}
                <Route path="/settlements" element={<SettlementList />} />t
                <Route path="/new-settlement" element={<NewSettlement />} />t
                <Route
                  path="/settlement/:id/partner-list"
                  element={<SettlementPartnerList />}
                />
                <Route
                  path="/settlement/:id/unsettled-partners/:partnerId"
                  element={<UnsettledPartner />}
                />
                <Route
                  path="/settlement/:id/settled-partners/:partnerId"
                  element={<SettledPartner />}
                />
                <Route
                  path="/settlement/direct-pv-history/:id/:partnerId"
                  element={<DirectPvHistory />}
                />
                <Route
                  path="/end-settlement/:id/"
                  element={<EndSettlement />}
                />
                <Route
                  path="/settlement-history/:id/"
                  element={<SettlementHistory />}
                />
                <Route path="/replace-emoney" element={<ReplaceEmoneyList />} />
                <Route
                  path="/replace-emoney-details/:id"
                  element={<ReplaceEmoneyDetails />}
                />
              </>
            )}

            {currentUserType === USER_TYPES.PARTNER && (
              <>
                <Route path="/" element={<Dashboard />} />
                <Route path="/partners" element={<Partners />} />
                <Route path="/partners-list" element={<PartnersList />} />
                <Route path="/tree/:id?" element={<Tree />} />
                <Route path="/partners/:type" element={<AddOrEditPartner />} />
                <Route
                  path="/partners/:type/:userId"
                  element={<AddOrEditPartner />}
                />
                <Route
                  path="/partners/update/:userId"
                  element={<UpdatePartnerData />}
                />
                <Route
                  path="/partners/request-e-money/"
                  element={<RequestEMoney />}
                />
                <Route
                  path="/partners/transfer-e-money/"
                  element={<TransferEMoney />}
                />
                <Route
                  path="/partners/replace-e-money"
                  element={<ReplaceEmoney />}
                />
                <Route
                  path="/partners/add-new-course/"
                  element={<AddNewCourse />}
                />
                <Route path="/partners/my-courses" element={<Mycourses />} />
                <Route
                  path="/partners/courses/:id"
                  element={<SingleCourse />}
                />
                <Route
                  path="/partners/courses/:id/:lessonId"
                  element={<CourseSingleLesson />}
                />
                <Route path="*" element={<Navigate to="/" />} />
              </>
            )}
            <Route path="*" element={<Navigate to="/" />} />
          </Route>
        </Routes>
      </div>
    </div>
  );
};

export default AuthenticatedRoutes;

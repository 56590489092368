import React, { useEffect, useState } from "react";
import SidebarCollapsableItem from "./SidebarCollapsableItem";
import { useSelector } from "react-redux";
import { USER_TYPES } from "../constants/globals";
import { pendingRequestsApi } from "../apis";

const Sidebar = () => {
  const { sidebarCollapsed } = useSelector((state) => state.UI);
  const { loggedInUser } = useSelector((state) => state.Auth);
  const currentUserType = loggedInUser?.data?.model?.user_type_id;
  const [requestsNum, setRequestsNum] = useState(0);

  useEffect(() => {
    if (currentUserType === USER_TYPES.ADMIN) {
      const fetchRequestsNum = async () => {
        try {
          const response = await pendingRequestsApi();
          if (response.data.status) {
            setRequestsNum(response.data.data || 0);
          } else {
            console.error("Failed to fetch indicators:", response.data.message);
          }
        } catch (error) {
          console.error("Error fetching indicators:", error);
        }
      };
      fetchRequestsNum();
    }
  }, [currentUserType]);

  const getSidebarLinks = () => {
    switch (currentUserType) {
      case USER_TYPES.ADMIN:
        return [
          {
            title: "Admin",
            isCollapsable: true,
            heading: {
              icon: "person_outline",
              title: "Admin",
            },
            links: [
              { icon: "dashboard", title: "Dashboard", link: "/" },
              {
                icon: "assignment",
                title: `Requests (${requestsNum})`,
                link: "/requests",
              },
              {
                icon: "library_books",
                title: `Replace Emoney`,
                link: "/replace-emoney",
              },
              {
                icon: "people",
                title: "Partners",
                link: "/partners-list",
              },
              {
                icon: "library_books",
                title: "Reports",
                link: "/reports",
              },
              {
                icon: "tune",
                title: "Settlements",
                link: "/settlements",
              },
            ],
          },
        ];
      case USER_TYPES.MODERATOR:
        return [
          {
            title: "Moderator",
            isCollapsable: true,
            heading: {
              icon: "person_outline",
              title: "Moderator",
            },
            links: [
              { icon: "dashboard", title: "Dashboard", link: "/" },
              {
                icon: "assignment",
                title: `Requests`,
                link: "/requests",
              },
              {
                icon: "library_books",
                title: `Replace Emoney`,
                link: "/replace-emoney",
              },
              {
                icon: "people",
                title: "Partners",
                link: "/partners-list",
              },
              {
                icon: "library_books",
                title: "Reports",
                link: "/reports",
              },
              {
                icon: "tune",
                title: "Settlements",
                link: "/settlements",
              },
            ],
          },
        ];
      default:
        return [
          {
            title: "Partners",
            isCollapsable: true,
            heading: {
              icon: "person_outline",
              title: "Partners",
            },
            links: [
              { icon: "dashboard", title: "Dashboard", link: "/" },
              {
                icon: "people",
                title: "Partners List",
                link: "/partners-list",
              },
              { icon: "people", title: "Tree", link: "/tree" },
              {
                icon: "person_add",
                title: "Add New Partner",
                link: "/partners/add",
              },
              {
                icon: "payments",
                title: "Request E-Money",
                link: "/partners/request-e-money",
              },
              {
                icon: "payments",
                title: "Transfer E-Money",
                link: "/partners/transfer-e-money",
              },
              {
                icon: "payments",
                title: "Replace E-Money",
                link: "/partners/replace-e-money",
              },
              {
                icon: "library_books",
                title: "Add New Course",
                link: "/partners/add-new-course",
              },
              {
                icon: "library_books",
                title: "My Courses",
                link: "/partners/my-courses",
              },
            ],
          },
        ];
    }
  };

  const sidebarLinks = getSidebarLinks();

  return (
    !sidebarCollapsed && (
      <div
        className="mdk-drawer js-mdk-drawer"
        id="default-drawer"
        data-persistent
        data-opened
      >
        <div
          className="mdk-drawer__content tw-z-50 tw-h-full"
          style={{ width: 256 }}
        >
          <div
            className="sidebar sidebar-left sidebar-dark bg-dark o-hidden custom-sidebar tw-flex tw-flex-col tw-justify-between"
            data-perfect-scrollbar=""
          >
            <div className="sidebar-p-y px-2 tw-py-5">
              {sidebarLinks.map((sidebarItem, index) => (
                <div key={index}>
                  <SidebarCollapsableItem sidebarItem={sidebarItem} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default Sidebar;
